import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import Components from '@article/MDX/Components';
import MDXBody from '@article/MDX/Body';

const MDX = ({ content }) => {
  return (
    <MDXProvider components={Components}>
      <MDXBody>
        <MDXRenderer>{content}</MDXRenderer>
      </MDXBody>
    </MDXProvider>
  );
};

export default MDX;
