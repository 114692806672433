import React, { useState } from 'react';
import Highlight, { defaultProps } from 'prism-react-renderer';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import CopyToClipboard from '@util/CopyToClipboard';
import styled from 'styled-components';

const Copy = ({ toCopy }) => {
  const [hasCopied, setHasCopied] = useState(false);

  function copyToClipboardOnClick() {
    if (hasCopied) return;

    CopyToClipboard(toCopy);
    setHasCopied(true);

    setTimeout(() => {
      setHasCopied(false);
    }, 2000);
  }

  return (
    <CopyButton onClick={copyToClipboardOnClick} data-a11y="false">
      {hasCopied ? <>Copied</> : <>Copy</>}
    </CopyButton>
  );
};

const CopyButton = styled.button`
  position: absolute;
  right: 22px;
  top: 24px;
  padding: 8px 12px 7px;
  border-radius: 5px;
  color: #6f7177;
  transition: background 0.3s ease;
  &:hover {
    background: rgba(255, 255, 255, 0.07);
  }
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -2%;
    top: -2%;
    width: 104%;
    height: 104%;
    border: 2px solid ${(p) => p.theme.color.font.dimmer};
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.01);
  }

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: none;
  }
`;

const CodeContainer = styled.div`
  // overflow: scroll;
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  font-size: 13px;
  margin: 15px 0 50px;
  border-radius: 5px;
  textarea,
  pre {
    padding: 32px !important;
  }
`;

const CodeBlock = ({ children, className, live }) => {
  // TODO: move pre styling to styled component or injected style
  const language = className.replace(/language-/, '');

  if (live) {
    return (
      <CodeContainer>
        <LiveProvider code={children} noInline={true}>
          <LivePreview />
          <LiveEditor />
          <LiveError />
        </LiveProvider>
      </CodeContainer>
    );
  }
  return (
    <Highlight {...defaultProps} code={children} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{
            ...style,
            padding: '32px',
            position: 'relative',
            overflow: 'auto',
            borderRadius: '5px',
          }}
        >
          <Copy toCopy={children} />
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default CodeBlock;
