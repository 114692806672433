import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImageQuery = (props) => {
  const { src, alt } = props;
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { in: ["png", "jpg", "jpeg"] } }) {
        edges {
          node {
            relativePath
            sourceInstanceName
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  const image = allFile.edges.find(
    (edge) =>
      `/${edge.node.sourceInstanceName}/${edge.node.relativePath}` === src
  );

  if (!image) {
    return <img alt="" {...props} />;
  }

  return (
    <GatsbyImage image={image.node.childImageSharp.gatsbyImageData} alt={alt} />
  );
};

export default ImageQuery;
