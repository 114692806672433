import styled, { css } from 'styled-components';

const IMAGE_WIDTHS = {
  regular: '680px',
  large: '1004px',
  full: '100vw',
};

const ImageStyles = css`
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    z-index: 0;
    border-radius: 5px;
  }

  div.Image__Small {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 100%;
    height: auto;
    z-index: 0;
    margin: 15px auto 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 680px;

    @media (max-width: ${(p) => p.theme.screen.lg}) {
      max-width: 507px;
    }

    @media (max-width: ${(p) => p.theme.screen.md}) {
      max-width: 486px;
      margin: 0 auto 25px;
    }
  }

  .Image__Container {
    text-align: center;
  }

  img.Image__With-Shadow {
    box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.15);
  }

  div.Image__Medium {
    position: relative;
    margin: 15px auto 50px;
    width: 100%;
    max-width: ${IMAGE_WIDTHS.large};
  }

  div.Image__Large {
    position: relative;
    left: calc((1200px - 32px - ${IMAGE_WIDTHS.full}) / 2);
    width: ${IMAGE_WIDTHS.full};
    margin: 25px auto 60px;
    pointer-events: none;

    iframe {
      pointer-events: all;
    }

    img {
      border-radius: 0 !important;
    }

    @media (max-width: ${(p) => p.theme.screen.lg}) {
      left: calc((960px - 32px - ${IMAGE_WIDTHS.full}) / 2);
      margin: 0 auto 25px;
    }

    @media (max-width: ${(p) => p.theme.screen.md}) {
      left: 0;
      width: 100%;
      margin: 0 auto 25px;
    }
  }
`;

const PrismStyles = css`
  .prism-code {
    overflow: auto;
    width: 100%;
    max-width: 744px;
    margin: 0 auto;
    padding: 32px;
    font-size: 13px;
    margin: 15px auto 50px;
    border-radius: 5px;
    .token-line {
      border-left: 3px solid transparent;
      & > span {
      }
    }
    .number-line {
      display: inline-block;
      width: 32px;
      user-select: none;
      opacity: 0.3;
      color: #dcd9e6;
      @media (max-width: ${(p) => p.theme.screen.md}) {
        opacity: 0;
        width: 0;
      }
    }
    .token-line.highlight-line {
      margin: 0 -32px;
      padding: 0 32px;
      @media (max-width: ${(p) => p.theme.screen.md}) {
        margin: 0 -20px;
        padding: 0 20px;
      }
    }
    .operator + .maybe-class-name {
      color: #ffcf74 !important;
    }
    .plain ~ .operator {
      color: #5fa8aa !important;
    }
    @media (max-width: ${(p) => p.theme.screen.lg}) {
      left: -26px;
    }
    @media (max-width: ${(p) => p.theme.screen.md}) {
      max-width: 526px;
      padding: 20px 20px;
      left: 0;
    }
    @media (max-width: ${(p) => p.theme.screen.sm}) {
      text-size-adjust: none;
      border-radius: 0;
      margin: 0 auto 25px;
      padding: 25px 20px;
      overflow: initial;
      width: unset;
      max-width: unset;
      float: left;
      min-width: 100%;
      overflow: initial;
      position: relative;
    }
  }
`;

const MDXBody = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${ImageStyles}
  ${PrismStyles}
`;

export default MDXBody;
