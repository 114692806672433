import React from 'react';
import CodeBlock from '@article/CodeBlock';
import styled, { css } from 'styled-components';

const Paragraph = styled.p`
  line-height: 1.756;
  font-size: 18px;
  font-family: ${(props) => props.theme.font.markdown};
  color: ${(props) => props.theme.color.font.regular};
  margin: 0 auto 35px;
  max-width: 680px;

  b {
    font-weight: 800;
  }

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    max-width: 507px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
    margin: 0 auto 25px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
    margin: 0 auto 25px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: 0 20px;
  }
`;

const Link = styled.a`
  color: ${(props) => props.theme.color.font.link};
  text-decoration: none;
  // background: linear-gradient(to top, transparent 30%, ${(props) =>
    props.theme.color.font.highlight} 1% 69%, transparent 25%);
`;

/**
 * Be careful when formatting this styled component
 * background-image url parameter needs to be in double quotes.
 */
const HorizontalRule = styled.hr`
  position: relative;
  width: 100%;
  max-width: 680px;
  margin: 50px auto;
  border: 0;
  height: 14.36px;
  background-image: url('${(p) => p.theme.divider}');
  background-repeat: repeat-x;
  box-sizing: border-box;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    max-width: 507px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: 0 20px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    width: calc(100vw - 40px);
    margin: 50px auto 50px;
  }
`;

export const commonHeadingStyles = css`
  font-weight: bold;
  color: ${(p) => p.theme.color.regular};
  max-width: 680px;
  width: 100%;
  margin: 25px auto 18px;
  word-break: keep-all;
  margin-bottom: 25px;

  @media (max-width: ${(p) => p.theme.screen.lg}) {
    max-width: 507px;
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    max-width: 486px;
    margin: 0 auto 25px;
  }

  @media (max-width: ${(p) => p.theme.screen.sm}) {
    padding: 0 20px;
  }
`;

const Headings = {
  h1: styled.h1`
    ${commonHeadingStyles}
  `,
  h2: styled.h2`
    ${commonHeadingStyles}
  `,
  h3: styled.h3`
    ${commonHeadingStyles}
  `,
  h4: styled.h4`
    ${commonHeadingStyles}
  `,
  h5: styled.h5`
    ${commonHeadingStyles}
  `,
  h6: styled.h6`
    ${commonHeadingStyles}
  `,
};

const List = {
  unordered: styled.ul`
    list-style: none;
    counter-reset: list;
    font-family: ${(p) => p.theme.font.markdown};
    color: ${(p) => p.theme.color.font.regular};
    position: relative;
    margin: 0 auto;
    padding: 15px 0 30px 30px;
    font-size: 18px;
    width: 100%;
    max-width: 680px;

    @media (max-width: ${(p) => p.theme.screen.lg}) {
      max-width: 507px;
    }

    @media (max-width: ${(p) => p.theme.screen.md}) {
      max-width: 486px;
      padding-left: 0px;
    }

    @media (max-width: ${(p) => p.theme.screen.sm}) {
      padding-left: 20px;
    }

    li {
      position: relative;
      padding-bottom: 15px;

      @media (max-width: ${(p) => p.theme.screen.md}) {
        padding-left: 30px;
      }

      @media (max-width: ${(p) => p.theme.screen.sm}) {
        padding-left: 30px;
      }

      p {
        @media (max-width: ${(p) => p.theme.screen.md}) {
          padding: 0;
        }
      }
    }

    li > :not(ol, ul) {
      display: inline;
    }
    li::before {
      width: 3rem;
      display: inline-block;
      position: absolute;
      color: ${(p) => p.theme.color.font.dim};
    }
    li::before {
      content: '';
      position: absolute;
      left: -30px;
      top: 6px;
      height: 8px;
      width: 8px;
      background: ${(p) => p.theme.color.font.dim};

      @media (max-width: ${(p) => p.theme.screen.md}) {
        left: 0;
      }
    }
  `,
  ordered: styled.ol`
    list-style: none;
    counter-reset: list;
    font-family: ${(p) => p.theme.font.markdown};
    color: ${(p) => p.theme.color.font.regular};
    position: relative;
    margin: 0 auto;
    padding: 15px 0 30px 30px;
    font-size: 18px;
    width: 100%;
    max-width: 680px;

    @media (max-width: ${(p) => p.theme.screen.lg}) {
      max-width: 507px;
    }

    @media (max-width: ${(p) => p.theme.screen.md}) {
      max-width: 486px;
      padding-left: 0px;
    }

    @media (max-width: ${(p) => p.theme.screen.sm}) {
      padding-left: 20px;
    }

    li {
      position: relative;
      padding-bottom: 15px;

      @media (max-width: ${(p) => p.theme.screen.md}) {
        padding-left: 30px;
      }

      @media (max-width: ${(p) => p.theme.screen.sm}) {
        padding-left: 30px;
      }

      p {
        @media (max-width: ${(p) => p.theme.screen.md}) {
          padding: 0;
        }
      }
    }

    li > :not(ol, ul) {
      display: inline;
    }

    li::before {
      width: 3rem;
      display: inline-block;
      position: absolute;
      color: ${(p) => p.theme.color.font.dim};
    }

    li::before {
      counter-increment: list;
      content: counter(list) '.';
      font-weight: 600;
      position: absolute;
      left: -2rem;
      top: 0;
      font-size: 1.1rem;

      @media (max-width: ${(p) => p.theme.screen.md}) {
        left: 0;
      }
    }
  `,
};

const Blockquote = styled.blockquote`
  margin: 15px auto 50px;
  color: ${(p) => p.theme.color.font.regular};
  font-family: ${(p) => p.theme.font.secondary};
  font-style: italic;

  & > p {
    font-family: ${(p) => p.theme.font.secondary};
    max-width: 880px !important;
    padding-right: 100px;
    padding-bottom: 0;
    width: 100%;
    margin: 0 auto;
    ${(p) => p.theme.font_size.larger};
    font-weight: bold;

    @media (max-width: ${(p) => p.theme.screen.sm}) {
      ${(p) => p.theme.font_size.large};
      padding: 0 180px;
    }

    @media (max-width: ${(p) => p.theme.screen.md}) {
      ${(p) => p.theme.font_size.larger};
      padding: 0 20px 0 40px;
    }
  }

  @media (max-width: ${(p) => p.theme.screen.md}) {
    margin: 10px auto 35px;
  }
`;

const Figcaption = styled.figcaption`
  color: ${(p) => p.theme.color.font.regular};
  font-family: ${(p) => p.theme.font.markdown};
  ${(p) => p.theme.font_size.small};
  opacity: 0.4;
  text-align: center;
  width: 100%;
  padding-top: 6px;

  @media (max-width: ${(p) => p.theme.screen.md}) {
    ${(p) => p.theme.font_size.xsmall};
  }
`;

const Img = styled.img``;

const PreviewComponents = {
  img: Img,
  p: Paragraph,
  a: Link,
  hr: HorizontalRule,
  h1: Headings.h1,
  h2: Headings.h2,
  h3: Headings.h3,
  h4: Headings.h4,
  h5: Headings.h5,
  h6: Headings.h6,
  ul: List.unordered,
  ol: List.ordered,
  blockquote: Blockquote,
  pre: (props) => <div {...props} />,
  code: CodeBlock,
  figcaption: Figcaption,
};

export default PreviewComponents;
