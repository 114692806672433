import React, { useCallback, useState } from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import { withTheme } from 'styled-components';
import 'react-medium-image-zoom/dist/styles.css';
import ImageQuery from '@common/ImageQuery';

const ImageZoom = withTheme((props) => {
  const [isZoomed, setIsZoomed] = useState(false);
  // TODO: replace overlay bg color with theme primary)
  const { theme } = props;
  const image = {
    ...props,
    className: 'Image__Zoom',
    style: {
      display: 'block',
      margin: '0 auto',
      width: '100%',
      borderRadius: '5px',
    },
  };

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  return (
    <ControlledZoom
      isZoomed={isZoomed}
      onZoomChange={handleZoomChange}
      zoomMargin={40}
      overlayBgColorEnd={theme.color.secondary}
    >
      <ImageQuery
        className={image.className}
        src={image.src}
        alt={image.alt}
        style={image.style}
      />
    </ControlledZoom>
  );
});

export default ImageZoom;
